import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import reducerName from './name'
import { resultLabels } from '../../helpers/copy'
import { isNullOrUndefined } from '../../helpers/utils'
import { calcBusinessDays } from '../../utils/calcBusinessDays'

export const user = createSlice({
  name: reducerName,
  initialState: {
    user: { personalSettings: {}, notes: [], toolpathway: [], questionnairePathway: [], mostPopular: [], trackerAnswers: [] },
    selectedView: null,
    viewLoading: false,
    tenant: false,
    selectedEntity: false,
    dashboardData: {
      totalUsers: 0,
      trackerAnswers: [],
      evaluationReportResult: false
    },
    userRead: [],
    showToolPathwarOrQuestionnaire: 'toolpathway', // 'questionnaire'
    loadedPathways: false,
    userResults: [],
    indicatorResults: [],
    userRole: {
      role: 'user',
      features: []
    }
  },
  reducers: {
    setUser: (state, { payload }) => {
      state.tenant = { ...payload.client, resultLabels: resultLabels.orgView }
      if (payload.userRead) state.userRead = payload.userRead
      if (payload.views) state.selectedView = payload.views[payload.views.length - 1]
      state.user = {
        ...state.user,
        ...payload,
        resultLabels: resultLabels.userView,
        missingDates: state.user.missingDates
      }
    },
    resetUser: (state) => {
      state.user = { personalSettings: {}, notes: [], toolpathway: [], questionnairePathway: [] }
    },
    setUserName: (state, { payload }) => {
      state.user.name = payload
    },
    setUserNotes: (state, { payload }) => {
      state.user = {
        ...state.user,
        notes: payload
      }
    },
    setPersonalSettings: (state, { payload }) => {
      state.user.personalSettings = { ...state.user.personalSettings, ...payload.personalSettings }
    },
    pushUserNotes: (state, { payload }) => {
      state.user.notes = [...state.user.notes, payload]
    },
    setProfilePic: (state, { payload }) => {
      state.user = { ...state.user, profilePic: payload }
    },
    setUserMissingDates: (state, { payload }) => {
      state.user = { ...state.user, missingDates: payload }
    },
    setUserTrackerAnswer: (state, { payload }) => {
      state.user = { ...state.user, trackerAnswers: payload.trackerAnswers }
    },
    addUserTrackerAnswer: (state, { payload }) => {
      payload.trackerAnswers.forEach(trackerAnswer => {
        const alreadyExists = state.user.trackerAnswers.findIndex(item => item.SK === trackerAnswer.SK)
        if (alreadyExists > -1) { state.user.trackerAnswers[alreadyExists] = { ...trackerAnswer } } else { state.user.trackerAnswers.push(trackerAnswer) }
      })
    },
    addDashboardDataTrackerAnswers: (state, { payload }) => {
      payload.trackerAnswers.forEach(trackerAnswer => {
        const alreadyExists = state.dashboardData.trackerAnswers.findIndex(item => item.SK === trackerAnswer.SK)
        if (alreadyExists > -1) { state.dashboardData.trackerAnswers[alreadyExists] = { ...trackerAnswer } } else { state.dashboardData.trackerAnswers.push(trackerAnswer) }
      })
    },
    setUserToolpathway: (state, { payload }) => {
      state.user = { ...state.user, toolpathway: payload }
    },
    setUserQuestionnairePathway: (state, { payload }) => {
      state.user = { ...state.user, questionnairePathway: payload }
      state.loadedPathways = true
    },
    setUserMostPopular: (state, { payload }) => {
      state.user = { ...state.user, mostPopular: payload }
    },
    toggleToolpathwayOrQuestionnaire: (state) => {
      state.showToolPathwarOrQuestionnaire = state.showToolPathwarOrQuestionnaire === 'toolpathway' ? 'questionnaire' : 'toolpathway'
    },
    setUserRead: (state, { payload }) => {
      if (!state.userRead.find(i => i.sk === payload.sk)) state.userRead.push(payload)
    },



    setDashboardData: (state, { payload }) => {
      state.dashboardData = { ...state.dashboardData, ...payload }
    },
    setEvaluationReportResult: (state, { payload }) => {
      state.dashboardData = { ...state.dashboardData, evaluationReportResult: { ...payload, ...payload.modelResult } }
    },
    setIndicatorReportResult: (state, { payload }) => {
      state.dashboardData = { ...state.dashboardData, indicatorReportResult: { ...payload, ...payload.modelResult } }
    },



    
    setViewLoading: (state, { payload }) => {
      state.viewLoading = payload
    },
    setSelectedView: (state, { payload }) => {
      state.selectedView = payload
    },
    setWeekAnswers: (state, { payload }) => {
      state.user = payload
    },
    setUserRole: (state, { payload }) => {
      state.userRole = payload
    },
    setlastUpdateDay: (state, { payload }) => {
      if (!payload?.trackerAnswers.length) return
      const { trackerAnswers } = payload
      const lastDay = trackerAnswers.reduce((acc, i) => Math.max(acc, i.createdAt), 0)
      const businessDaysDiff = calcBusinessDays(lastDay, moment())
      state.user.lastUpdateDaysDiff = Math.min(businessDaysDiff, state.user.lastUpdateDaysDiff)
      state.user.lastUpdateDay = (moment(state.user.lastUpdateDay).diff(moment(lastDay), 'days') > 0 ? state.user.lastUpdateDay : lastDay)

    }
  }
})

export const setSelectedEntity = view => async (dispatcher, getState) => {
  const evaluationReportResult = view?.results?.length ? { ...view.results[view.results.length - 1], ...view.results[view.results.length - 1].modelResult } : false
  const indicatorReportResult = view?.indicatorResults?.length
    ? { ...view.indicatorResults[view.indicatorResults.length - 1], ...view.indicatorResults[view.indicatorResults.length - 1].modelResult }
    : false
  const getAllEvaluationResults = view?.totalResults?.length ? [...view.totalResults] : false 
  const getAllIndicatorResults = view?.totalIndicatorResults?.length ? [...view.totalIndicatorResults] : false  
  const dashboardData = {
    resultLabels: view && view.resultLabels ? view.resultLabels : resultLabels.userView,
    ...(view && !isNullOrUndefined(view.totalUsers) && { totalUsers: view.totalUsers }),
    ...(view && !isNullOrUndefined(view.trackerTotalUsers) && { trackerTotalUsers: view.trackerTotalUsers }),
    ...(view && !isNullOrUndefined(view.evaluationTotalUsers) && { evaluationTotalUsers: view.evaluationTotalUsers }),
    ...(view && !isNullOrUndefined(view.trackerAnswers) && { trackerAnswers: view.trackerAnswers }),
    evaluationReportResult,
    indicatorReportResult,
    getAllEvaluationResults,
    getAllIndicatorResults
  }
  dispatcher(setDashboardData(dashboardData))
  dispatcher(setViewLoading(false))
}

export const selectView = view => async (dispatcher) => {
  dispatcher(setSelectedView(view))
  dispatcher(setSelectedEntity({}))
  dispatcher(setViewLoading(true))
}
export const dashboardFilterChanged = ({ view, entity }) => async (dispatcher) => {
  dispatcher(setSelectedEntity(entity))
}

export const {
  setUser,
  resetUser,
  setPersonalSettings,
  setUserName,
  setUserNotes,
  setProfilePic,
  setUserRead,
  setWeekAnswers,
  setDashboardData,
  setEvaluationReportResult,
  setIndicatorReportResult,
  setSelectedView,
  setFilterDate,
  setViewLoading,
  setUserTrackerAnswer,
  addUserTrackerAnswer,
  addDashboardDataTrackerAnswers,
  setUserToolpathway,
  setUserMostPopular,
  setUserQuestionnairePathway,
  toggleToolpathwayOrQuestionnaire,
  setUserMissingDates,
  setUserRole,
  setlastUpdateDay,
  pushUserNotes
} = user.actions

// Reducer
export default user.reducer

