const highDescriptions = {
  1: 'Your Average Highest Score of 1 places your current highest potential Resilience level as in Breakdown. Your resilience level means right now that you need to seek help, support, love and health. This is a serious issue, and you need to consider your health right now.',
  2: 'Your Average Highest Score of 2 places your current highest potential Resilience level as in Fragmentation. There are signs of real fragmentation in your life, and this is dangerous for your health. This could become a serious or persistent issue, and you need to stop, rest and seek help.',
  3: 'Your Average Highest Score of 3 places your current highest Resilience level as in Coping. This is definitely something to aim for!',
  4: 'Your Average Highest Score of 4 places your current highest Resilience level as in Bounce Back. This is definitely something to aim for!',
  5: 'Your Average Highest Score of 5 places your current highest Resilience level as on the Whoosh. This is definitely something to aim for!',
  6: 'Your Average Highest Score of 6 places your current highest Resilience level as in Breakthrough. This is definitely something to aim for!'
}

const lowDescriptions = {
  1: 'Your Average lowest Score of 1 places your current lowest potential Resilience level as in Breakdown. This is where you are in danger of dropping to. Examine the items you scored your lowest and consider how you might make a shift in these.',
  2: 'Your Average lowest Score of 2 places your current lowest potential Resilience level as in Fragmentation. This is where you are in danger of dropping to. Examine the items you scored your lowest and consider how you might make a shift in these.',
  3: 'Your Average lowest Score of 3 places your current lowest potential Resilience level as in Coping. This is where you are in danger of dropping to. Examine the items you scored your lowest and consider how you might make a shift in these.',
  4: 'Your Average lowest Score of 4 places your current lowest potential Resilience level as in Bounce Back. This is where you are in danger of dropping to. Examine the items you scored your lowest and consider how you might make a shift in these.',
  5: 'Your Average lowest Score of 5 places your current lowest potential Resilience level as on the Whoosh. This is where you are in danger of dropping to. Examine the items you scored your lowest and consider how you might make a shift in these.',
  6: 'Your Average lowest Score of 6 places your current lowest potential Resilience level as in Breakthrough. This is a great place to be for your average low scores. To make sure things don`t slip, examine the items you scores your lowest and consider ow you might make a shift in these.'
}

const levelDescriptions = {
  Breakdown: [
    'Your Resilience Dynamic® Questionnaire report has identified that your resilience may be quite low at the moment.',
    'We know that it is normal for human resilience to go up and down and any of us can find ourselves at lower states of resilience. This can be for a number of reasons including the current context that we are experiencing at work and/ or in our wider life.',
    'In your upcoming debrief session, your coach will help you to explore this more fully. They will check in with you to discuss whether or not you feel the report reflects a true picture or otherwise of what you are currently experiencing. And will focus on the resources that you do have and some simple things to take away that can help support your resilience.',
    'The debrief with your coach should already be scheduled within the next 2 - 3 days. In the meantime we suggest that you focus as best you can on taking care of yourself and getting any support you might need.',
    'If for any reason your debrief session is not already organised within the next 2 - 3 days please contact <a href="mailto:support@resilienceengine.com">support@resilienceengine.com</a> so we can be sure to arrange contact soon.'
  ],
  Fragmentation: [
    'Your Resilience Dynamic® Questionnaire report has identified that your resilience may be quite low at the moment.',
    'We know that it is normal for human resilience to go up and down and any of us can find ourselves at lower states of resilience. This can be for a number of reasons including the current context that we are experiencing at work and/ or in our wider life.',
    'In your upcoming debrief session, your coach will help you to explore this more fully. They will check in with you to discuss whether or not you feel the report reflects a true picture or otherwise of what you are currently experiencing. And will focus on the resources that you do have and some simple things to take away that can help support your resilience.',
    'The debrief with your coach should already be scheduled within the next 2 - 3 days. In the meantime we suggest that you focus as best you can on taking care of yourself and getting any support you might need.',
    'If for any reason your debrief session is not already organised within the next 2 - 3 days please contact <a href="mailto:support@resilienceengine.com">support@resilienceengine.com</a> so we can be sure to arrange contact soon.'
  ],
  'Between Fragmentation and Coping': [
    'You are no longer coping so well and might oscillate between the feelings of coping and then being really overwhelmed. There are signs of fragmentation in your life, and this could become dangerous for your health.',
    'To increase your resilience, you need to consider how to cope more consistently. First and foremost is to get help, and part of this help should be helping you stop unhelpful things that are draining your energy.',
    'Overall, you will more or less just cope, or in fact cope only some of the time, will be moving up and down this line. You will feel unstable, and it triggers a lot of stress. The nearer to fragmentation, the higher the stress. You may have very little capacity for change. Instead, you might seek control - and so will resist change, or the opposite, you may relinquish all control even if you have resposibilities.',
    'Stabilising your resilience levels at coping is something to aim for. That will take you accepting that you need to pay attention to this from now, and considering how you can increase your energy, even a little.'
  ],
  Coping: [
    'You cope with your life, perhaps very well. This is the first healthy state of  resilience, so it`s a good first base! Coping is where you manage things day to day. You do not have surplus energy for anything else, but at least you manage the must do`s in your work and life.',
    'You may feel dissatisfied, you may feel exhausted, and indeed, you may resist change and often feel stuck.',
    'You can increase your resilience in the first instance by considering two things: Helping yourself `be present`. Stop `doing` and start the `being`. This means living more mindfully and in the present. Secondly, consider your energy, and how to increase and stabilise it.'
  ],
  'Break Even': [
    'Life is OK at Breakeven. Maybe a bit stuck, but it`s all right.',
    'You manage, you can sometimes feel like there`s some surplus energy and that you can relax a bit. You may feel stress quite often, not dangerously, but nevertheless, it will hijack you sometimes. At these points, you will draw on your coping mechanisms, which may drain energy and leave you tired.',
    'Your way to shift to higher levels of resilience is to consider how to increase your energies.'
  ],
  'Bounce Back': [
    'This is a state of resilience where you can perform at a high level. It can feel powerful. That feeling may be inconsistent, but it`s definetely a state where you manage to bounce back from difficulties and setbacks.',
    'The inevitable ups and downs of Bounceback can take it`s toll. You may feel low in energy, motivation and inconsistent in whether you see yourself as successful or not.',
    'You can increase your resilience by seriously considering your energy - how to stabilise it, and how to invest in it so it`s at the highest level day to day.',
    'Thereafter look at the meaning you draw from different contexts, and how often you can connect with the purpose of each. This connection is at the heart of your opportunity to increase your resilience in a sustainable way.'
  ],
  'Beginning of the Whoosh': [
    'Your resilience is already pretty high, and you are on your way to stabilising it at the higher levels. Great!',
    'Your resilience level allow you to perform well, take on change and challenge, and manage to keep your self-care and wellbeing routines going.',
    'When you are over-stretched or in new situations, you may find yourself seeking control in ways that aren`t productive, or you may also find yourself feeling exposed or lacking in confidence when you don`t have the knowledge or control you are used to.',
    'The key to higher and more stable resilience levels is alignment across all the contexts of your life - your work, your home life, your relationships and your own self-care. To understand what opportunities you have for your resilience, you will need to enquire, accept and potentially let go of old habits the no longer serve you well.'
  ],
  Whoosh: [
    'Your resilience is already pretty high, and you are on your way to stabilising it at the higher levels. Great!',
    'Your resilience level allow you to perform well, take on change and challenge, and manage to keep your self-care and wellbeing routines going.',
    'When you are over-stretched or in new situations, you may find yourself seeking control in ways that aren`t productive, or you may also find yourself feeling exposed or lacking in confidence when you don`t have the knowledge or control you are used to.',
    'The key to higher and more stable resilience levels is alignment across all the contexts of your life - your work, your home life, your relationships and your own self-care. To understand what opportunities you have for your resilience, you will need to enquire, accept and potentially let go of old habits the no longer serve you well.'
  ],
  'Whoosh towards Breakthrough': [
    'Your resilience is high and you probably feel at ease and good about life in most contexts. Great!',
    'You are able to adapt to change well, in quite a speedy way. Sometimes you may experience low motivation or that things are not all aligned. Indeed, you may not always be truthful with yourself about when your resilience is either variable, or when it`s at the lower end of the Whoosh.',
    'Your resilience can be increased by fine tuning all the parts of your life in an integrated way. Consider all the aspects that are important for your resilience, and see if you can align them more to work for you - in all contexts.'
  ],
  Breakthrough: [
    'You are resourceful, adaptable and energised at all times, able to respond speedily to change, without any real cost. This is the highest level of resilience that you can attain, and it`s a state of genuinely feeling good in your own skin. Brilliant!',
    'To sustain this, you will need to consider your capacity, your pacing, and being rigorous about choosing to do things only when they are meaningul for you. Youy may also need to do something genuinely new or difficult, so you are stepping outside of your comfort zone and having to learn. This will keep your resourcefulness alive and growing.'
  ]
}

const indicatorLevelDescriptions = {
  ...levelDescriptions,
  Breakdown: [
    'Your Resilience Dynamic® Indicator report has identified that your resilience may be quite low at the moment.',
    'We know that it is normal for human resilience to go up and down and any of us can find ourselves at lower states of resilience. This can be for a number of reasons including the current context that we are experiencing at work and/ or in our wider life.  ',
    'We\'ve not shared this information with anyone within your organisation, nor is it our plan to. We recommend you contact your employee support to find out more about the support options available to you within the organisation. And please, if you feel comfortable doing so, do let a manager know. You can also get in touch with the Resilience Engine at <a href="mailto:support@resilienceengine.com">support@resilienceengine.com</a>.'
  ],
  Fragmentation: [
    'Your Resilience Dynamic® Indicator report has identified that your resilience may be quite low at the moment.',
    'We know that it is normal for human resilience to go up and down and any of us can find ourselves at lower states of resilience. This can be for a number of reasons including the current context that we are experiencing at work and/ or in our wider life.  ',
    'We\'ve not shared this information with anyone within your organisation, nor is it our plan to. We recommend you contact your employee support to find out more about the support options available to you within the organisation. And please, if you feel comfortable doing so, do let a manager know. You can also get in touch with the Resilience Engine at <a href="mailto:support@resilienceengine.com">support@resilienceengine.com</a>.'
  ]
}

const unauthenticatedIndicatorLevelDescriptions = {
  ...levelDescriptions,
  Breakdown: [
    'Your Resilience Dynamic® Indicator report has identified that your resilience may be quite low at the moment.',
    'We know that it is normal for human resilience to go up and down and any of us can find ourselves at lower states of resilience. This can be for a number of reasons including the current context that we are experiencing at work and/ or in our wider life.  ',
    'We recommend you contact your employee support to find out more about the support options available to you within the organisation. And please, if you feel comfortable doing so, do let a manager know. You can also get in touch with the Resilience Engine at <a href="mailto:support@resilienceengine.com">support@resilienceengine.com</a>.'
  ],
  Fragmentation: [
    'Your Resilience Dynamic® Indicator report has identified that your resilience may be quite low at the moment.',
    'We know that it is normal for human resilience to go up and down and any of us can find ourselves at lower states of resilience. This can be for a number of reasons including the current context that we are experiencing at work and/ or in our wider life.  ',
    'We recommend you contact your employee support to find out more about the support options available to you within the organisation. And please, if you feel comfortable doing so, do let a manager know. You can also get in touch with the Resilience Engine at <a href="mailto:support@resilienceengine.com">support@resilienceengine.com</a>.'
  ]
}

const resultDescriptions = {
  score: `This is the average resilience level and corresponding
    score across the group that matches your filtered criteria.`,
  level: `Your Resilience Level is the mapping of your 
    Resilience Score on the Resilience Dynamic®. There are 9 possible 
    Resilience Levels ranging from Breakdown through to Breakthrough. `,
  range: `This is the span from the lowest average resilience scoresto 
    to the highest average resilience scores across the group that matches your filtered criteria.`,
  variability: `This is the average level of variability of the group that matches your filtered 
    criteria experiences between their highest and lowest scores
    across all resilience and wellbeing drivers.`,
  mostFrequent: `This is the most common resilience score across the group that matches your filtered criteria.
    The average cluster score shines a light on the dominating
    culture and reflects the expectations placed on your people.`
}

const resultLabels = {
  orgView: {
    result: 'Resilience Dynamic® Questionnaire Results',
    score: 'Resilience Score',
    level: 'Resilience Level',
    range: 'Average Range',
    variability: 'Average Variance',
    clusterScore: 'Average Cluster Score',
    mostFrequent: 'Most Common Resilience Score'
  },
  userView: {
    result: 'Results',
    score: 'Resilience score',
    level: 'Resilience level',
    range: 'Resilience Range',
    variability: 'Average Variability'
  }
}

export { highDescriptions, lowDescriptions, levelDescriptions, indicatorLevelDescriptions, resultDescriptions, resultLabels, unauthenticatedIndicatorLevelDescriptions }
