import { Grid, MenuItem, Paper, Select, Typography } from '@mui/material'
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import useDashboardContext from '../../hooks/useDashboardContext'
import useFeatures from '../../hooks/useFeatures'
import { aggregationConfigs } from '../../reducers'
import { RenderCharts } from './charts'
import { useStyles } from './styles'

// forceQuestionnaireOrIndicator should be 'resilience' or 'indicator'
const QuestionnaireIndicatorCard = ({ forceQuestionnaireOrIndicator = null }) => {
  const classes = useStyles()
  const { filters } = useDashboardContext()
  const features = useFeatures()
  const dispatch = useDispatch()

  const selectOptions = useMemo(() => {
    const options = []
    if (features.RESILIENCE_INDICATOR && features.RESILIENCE_EVALUATION) options.push({ key: 'both', name: 'Resilience Dynamic® Questionnaire & Indicator' })
    if (features.RESILIENCE_EVALUATION) options.push({ key: 'resilience', name: 'Resilience Dynamic® Questionnaire' })
    if (features.RESILIENCE_INDICATOR) options.push({ key: 'indicator', name: 'Resilience Dynamic® Indicator' })
    return options
  }, [features])

  const title = useMemo(() => {
    if (forceQuestionnaireOrIndicator === 'resilience') return 'Resilience Dynamic® Questionnaire'
    if (forceQuestionnaireOrIndicator === 'indicator') return 'Resilience Dynamic® Indicator'
    if (features.RESILIENCE_INDICATOR && features.RESILIENCE_EVALUATION) return 'Resilience Dynamic® Questionnaire & Indicator'
    if (features.RESILIENCE_EVALUATION) return 'Resilience Dynamic® Questionnaire'
    if (features.RESILIENCE_INDICATOR) return 'Resilience Dynamic® Indicator'
    return ''
  }, [features, forceQuestionnaireOrIndicator])

  const handleChange = useCallback((event) => {
    dispatch(aggregationConfigs.actions.setFilterType(event.target.value))
  }, [dispatch])

  useEffect(() => {
    if (forceQuestionnaireOrIndicator) return
    let value = ''
    if (features.RESILIENCE_INDICATOR && features.RESILIENCE_EVALUATION) {
      value = 'both'
    } else if (features.RESILIENCE_EVALUATION) {
      value = 'resilience'
    } else {
      value = 'indicator'
    }
    dispatch(aggregationConfigs.actions.setFilterType(value))

  }, [features, dispatch, forceQuestionnaireOrIndicator])

  return (
    <Paper elevation={2} className={classes.paper}>
      <Typography variant='h5' marginLeft={2} marginBottom={2}>{title}</Typography>
      {forceQuestionnaireOrIndicator === null &&
        <Grid xs={3} marginLeft={2} marginBottom={2}>
          <Select
            autoWidth
            value={filters.type}
            onChange={handleChange}
            size='small'
          >
            {selectOptions.map(i => (
              <MenuItem key={i.key} value={i.key}>{i.name}</MenuItem>
            ))}
          </Select>
        </Grid>}
      <RenderCharts />
    </Paper>

  )
}

export default QuestionnaireIndicatorCard
