import { ButtonBase, Grid, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { useCallback, useMemo } from 'react'
import useCICOContext from '../../../hooks/useCICOContext'
import CICOProgress from '../cicoProgress'
import { useStyles } from './style'

const CICOHeader = () => {
  const { cicoSubType, selectedStep, setSelectedStep, loading, info, allProgress, setCicoSubType, setSelectedItem } = useCICOContext()
  const title = useMemo(() => info?.title, [info?.title])
  const progress = useMemo(() => allProgress || 0, [allProgress])
  const hasFastTrack = useMemo(() => info?.hasFastTrack, [info?.hasFastTrack])
  const backToSteps = useCallback(() => setSelectedStep(null), [setSelectedStep])

  if (loading) return null
  return (
    <Grid container justifyContent='space-between' alignItems='center' marginBottom={1}>
      <Grid display='flex' flexDirection='column'>
        <Grid display='flex' alignItems='center' gap={2}>
          <Typography variant='h6' color='text.greyDark'>{title}</Typography>
          {cicoSubType !== 'caseStudy' && hasFastTrack && <FastTrackToggleButton />}
        </Grid>
        {cicoSubType === 'caseStudy' && <BackToButton onClick={() => { setCicoSubType('fast'); setSelectedItem(-1); }} backText={'Back to fast track'} />}
        {cicoSubType === 'depth' && selectedStep !== null && <BackToButton onClick={backToSteps} backText='Back to steps' />}
      </Grid>
      <Grid display='flex' alignItems='center' gap={2}>
        <Typography color='primary' variant='h6'>Your {title === 'Checkin Checkout' ? 'CICO' : ' Resilience Fundamentals'} Progress</Typography>
        <CICOProgress loading={false} size='medium' progress={progress} />
      </Grid>
    </Grid>
  )
}

const BackToButton = ({ onClick, backText }) => {
  return (
    <Grid marginTop={1}>
      <ButtonBase onClick={onClick}>
        <img src='/icons/left-arrow.svg' alt='left arrow' width={16} />
        <Typography marginLeft={1} variant='caption' fontSize={16} color='secondary'>{backText}</Typography>
      </ButtonBase>
    </Grid>
  )
}

const FastTrackToggleButton = () => {
  const { cicoSubType, setCicoSubType } = useCICOContext()
  const classes = useStyles()
  return (
    <ToggleButtonGroup
      value={cicoSubType}
      exclusive
      onChange={(_e, newAlign) => newAlign && setCicoSubType(newAlign)}
      aria-label='text alignment'
      className={classes.toggleButtonGroup}
    >
      <ToggleButton value='fast'>
        Fast Track
      </ToggleButton>
      <ToggleButton value='depth'>
        In Depth
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
export default CICOHeader
