import { IconButton, Table, TableHead, TableBody, TableCell, TableContainer, TableRow, Typography, Box } from '@mui/material'
import React, { useEffect } from 'react'
import { LowScoreMessage } from '../../components'
import { useStyles } from './style'
import GetAppIcon from '@mui/icons-material/GetApp'
import { ArrowLeft, ArrowRight } from '@mui/icons-material'

export const ReportsList = ({reports, headers, hasLowScoreReport, showLowScoreMessage, hasReports}) => {
    const classes = useStyles()
    const [currentPage, setCurrentPage] = React.useState(0)
    const [currentPosts, setCurrentPosts] = React.useState([])
    const postsPerPage = 10
    const lastPostIndex = currentPage === 0 ? 10 : (currentPage + 1) * postsPerPage
    const firstPostIndex = lastPostIndex - postsPerPage

    useEffect(() => {
        if (!!reports){
            setCurrentPosts(reports.slice(firstPostIndex, lastPostIndex))         
        }
    }, [reports, firstPostIndex, lastPostIndex])

    const pages = Math.ceil(reports?.length / postsPerPage)

    const getNextPage = () => {
        setCurrentPage(current => current + 1)
      }
      
    const getPreviousPage = () => {
        setCurrentPage(current => current - 1)
    }
    return (
        <>
            <TableContainer>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            {headers.map((header, key) => (
                                <TableCell key={key} align='center' className={classes.subtitle}>{header.title}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {hasLowScoreReport && !!showLowScoreMessage && (
                            <TableRow>
                                <TableCell colSpan={5}>
                                    <LowScoreMessage />
                                </TableCell>
                            </TableRow>)
                        }
                        {!!hasReports && currentPosts?.map((report, idx) => (
                            <TableRow key={idx}>
                                {report.showPdf && headers.map((header, key) => (
                                    <TableCell key={key} align='center'>
                                        {header.key === 'link'
                                            ? (
                                                <IconButton
                                                    title='Download PDF'
                                                    aria-label='Download PDF'
                                                    className={classes.resultBtn}
                                                    onClick={() => window.open(`${report[header.key]}`)}
                                                    size='large'
                                                >
                                                    <GetAppIcon fontSize='small' />
                                                </IconButton>)
                                            : (<Typography className={classes.result}> {report[header.key]} </Typography>
                                            )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
           { !!hasReports && <Box display='flex' alignItems='center' justifyContent='center'>
                <IconButton 
                    disabled={currentPage === 0} 
                    onClick={getPreviousPage}
                >
                    <ArrowLeft />
                </IconButton>
                <Typography>{currentPage + 1}</Typography>
                <IconButton 
                    disabled={(currentPage + 1 === pages)} 
                    onClick={getNextPage}
                >
                    <ArrowRight />
                </IconButton>
            </Box>}
        </>
    )
}
